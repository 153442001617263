(function () {
  /*
  * set height for stage teaser if data-height is set with a integer value
  */
  var setStageTeaserHeight = function (element) {
    var height = element.getAttribute('data-height');
    if (height != null && !isNaN(height)) {
      var currentStyle = '';
      if (element.getAttribute('style') != null) {
        currentStyle = element.getAttribute('style');
      }
      element.setAttribute('style', currentStyle + ' min-height: ' + height + 'px;');
    }
  };

  /*
  * set stage teaser background umage with style tag and media queries for a ID selecctor
  */
  var setStageTeaserImages = function () {
    var stageTeasers = document.querySelectorAll('[data-js-action="setImage"]'),
    views = [];
    var inlineStyle = '';
    var lastImage = '';

    for (var i = 0; i < stageTeasers.length; ++i) {
      var tmp_id = 'stageTeaser_' + i,
      stageItem = stageTeasers[i];
      //setStageTeaserHeight(stageItem);
      stageItem.id = tmp_id;
      var backgrounds = stageItem.attributes['data-backgrounds'];
      backgrounds = JSON.parse(backgrounds.value);

      for (var bg in backgrounds) {
        if(!Array.isArray(views[bg])) {
          views[bg] = [];
        }
        lastImage = backgrounds[bg].img;
        views[bg][tmp_id] = backgrounds[bg];
      }

      /*break if IE 8*/
      if (document.getElementsByTagName('html')[0].className.search('ielt9') !== -1) {
        var styleString = 'background-image: url("' + lastImage + '");';
        stageItem.setAttribute('style', styleString) ;
        return;
      }
    }

    for (var view in views) {
      inlineStyle += '@media (min-width: ' + view + 'px) { ';
      for (var id in views[view]) {
        if (views[view][id].img != undefined) {
          inlineStyle += ' #' + id + ' {background-image: url("' +  views[view][id].img + '");';
          if (views[view][id].height != undefined && views[view][id].height != 0) {
            inlineStyle += 'min-height: ' + views[view][id].height + 'px; ';
          }
          inlineStyle += '} ';
        }
      }
      inlineStyle += '} ';
    }
    var customCss = document.createElement('style');
    customCss.innerHTML = inlineStyle;
    document.head.appendChild(customCss);
  };

  /*
  * set stage teaser background umage depending on viewport size with every resize as a inline style direct
 */
  var setStageTeaserImageWithResize = function () {
    var stageTeasers = document.querySelectorAll('[data-js-action="setImage"]');
    for (var i = 0; i < stageTeasers.length; ++i) {
      var stageItem = stageTeasers[i],
      activeBgPath = '',
      backgrounds = stageItem.attributes['data-backgrounds'];
      stageItem.setAttribute('style', '');
      setStageTeaserHeight(stageItem);
      backgrounds = JSON.parse(backgrounds.value);
      for (var bg in backgrounds) {
        if (window.innerWidth <= bg) {
          activeBgPath = backgrounds[bg]['img'];
          break;
        }
      }
      if (activeBgPath == '') {
        //console.log(':: ' + Object.keys(backgrounds)[Object.keys(backgrounds).length-1]);
        activeBgPath = backgrounds[Object.keys(backgrounds)[Object.keys(backgrounds).length - 1]]['img'];
      }

      if (activeBgPath != '') {
        var currentStyle = '';
        if (stageItem.getAttribute('style') != null) {
          currentStyle = stageItem.getAttribute('style');
        }
        stageItem.setAttribute('style', currentStyle + ' background-image: url("' + activeBgPath + '")');
      }
    }
  };


  setStageTeaserImages();

  //setStageTeaserImageWithResize();

  //window.addEventListener('resize', function () {
  //    setStageTeaserImageWithResize();
  //});
})();
